import { axiosOnce as axios } from '@/axios_once'

export default {
  acceptCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/accept`)
  },
  cancelCleaningJob(jobId, params) {
    return axios.put(`/api/cleaning_jobs/${jobId}/cancel`, params)
  },
  callOffCleaningJob(jobId, params) {
    return axios.put(`/api/cleaning_jobs/${jobId}/call_off`, params)
  },
  getCalloffDetails(visitId) {
    return axios.get(`/api/calloffs/${visitId}`)
  },
  contactCleaningJobClient(jobId, params) {
    return axios.post(`/api/cleaning_jobs/${jobId}/contact_client`, params)
  },
  forceContactCleaningJobClient(jobId, params) {
    return axios.post(`/api/cleaning_jobs/${jobId}/force_contact_client`, params)
  },
  completeCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/complete`)
  },
  uncompleteCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/uncomplete`)
  },
  createAutoLineItem(params) {
    return axios.post('/api/auto_line_items', { auto_line_item: params })
  },
  expireCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/expire`)
  },
  sendFeedbackReminder(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/send_feedback_reminder`)
  },
  updateAutoLineItem(lineItemId, params) {
    return axios.put(`/api/auto_line_items/${lineItemId}`, { auto_line_item: params })
  },
  getAutoLineItems(params) {
    if (params.areaId) {
      return axios.get(`/api/service_areas/${params.areaId}/auto_line_items`)
    }
    if (params.cleanerId) {
      return axios.get(`/api/cleaners/${params.cleanerId}/auto_line_items`)
    }
  },
  deleteAutoLineItem(itemId) {
    return axios.delete(`/api/auto_line_items/${itemId}`)
  },
  getCleaningJob(jobId) {
    return axios.get(`/api/cleaning_jobs/${jobId}`)
  },
  getCleanerJobs(cleanerId, startDate, endDate) {
    return axios.get(`/api/cleaning_jobs?cleaner_id=${cleanerId}&start=${startDate}&end=${endDate}`)
  },
  getFeedbacksForCleaner(cleanerId, params) {
    return axios.get('/api/feedbacks?page=' + params.page + '&size=' + params.size + '&cleaner_id=' + cleanerId)
  },
  getFeedbacks(params) {
    return axios.get('/api/feedbacks?page=' + params.page + '&size=' + params.size)
  },
  getCalloffsForCleaner(cleanerId, params) {
    return axios.get('/api/calloffs?page=' + params.page + '&size=' + params.size + '&cleaner_id=' + cleanerId)
  },
  removeCalloffByEventId(eventId) {
    return axios.delete(`/api/calloffs/${eventId}`)
  },
  getPermissions() {
    return axios.get('/api/profile/permissions')
  },
  getPermissionsForUser(userId) {
    return axios.get(`/api/permissions?user_id=${userId}`)
  },
  updatePermissions(params) {
    return axios.post('/api/permissions', params)
  },
  rejectCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/reject`)
  },
  resetCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/reset`)
  },
  sendMeetupUrlToCleaner(reservationId) {
    return axios.put(`/api/reservations/${reservationId}/send_meetup_url_to_cleaner`)
  },
  requestToBecomeAvailable(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/request_to_become_available`)
  },
  requestPhoto(userId) {
    return axios.put(`/api/users/${userId}/request_photo`)
  },
  startCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/start`)
  },
  restartCleaningJob(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/restart`)
  },
  getRecentPaymentsOfClient(clientId) {
    return axios.get(`/api/recent_payments?client_id=${clientId}`)
  },
  getRecentPaymentsOfVisit(reservationId) {
    return axios.get(`/api/recent_payments?reservation_id=${reservationId}`)
  },
  getLastNotificationsForClient(clientId) {
    return axios.get(`/api/notifications/last?client_id=${clientId}`)
  },
  sendMissingBillingInfoNotifications(clientId) {
    return axios.put(`/api/clients/${clientId}/send_missing_billing_info`)
  },
  forceSendMissingBillingInfoNotifications(clientId) {
    return axios.put(`/api/clients/${clientId}/force_send_missing_billing_info`)
  },
  enableBooking(clientId) {
    return axios.put(`/api/clients/${clientId}/enable`)
  },
  getContacts() {
    return axios.get('/api/communications/contacts')
  },
  getChannelMessages({ userId, userType, channel, phone }) {
    var params = `?phone=${phone}&channel=${channel}`
    if (userId) {
      params += `&user_id=${userId}&user_type=${userType}`
    }
    return axios.get(`/api/communications/messages${params}`)
  },
  getEmails(all_params) {
    var params = ''
    if (all_params.clientId) {
      params += '?client_id=' + all_params.clientId
    } else {
      if (all_params.userId) {
        params += '?user_id=' + all_params.userId
      }
    }
    return axios.get('/api/communications/emails' + params)
  },
  getEmailContent(communicationId) {
    return axios.get('/api/communications/email/' + communicationId)
  },
  sendTextMessage(params) {
    return axios.post('/api/text_messages', { text_message: params })
  },
  getChangeRequests(areaId) {
    return axios.get(`/api/change_requests?area_id=${areaId}`)
  },
  getClientRequests() {
    return axios.get('/api/client_requests')
  },
  getCleanerMessages(cleanerId, extraParams = '') {
    if (extraParams) {
      return axios.get(`/api/cleaners/${cleanerId}/text_messages${extraParams}`)
    }
    return axios.get(`/api/cleaners/${cleanerId}/text_messages`)
  },
  getClientMessages(clientId, extraParams = '') {
    if (extraParams) {
     return axios.get(`/api/clients/${clientId}/text_messages${extraParams}`)
    }
    return axios.get(`/api/clients/${clientId}/text_messages`)
  },
  getCleanerPreferences(clientId) {
    return axios.get(`/api/clients/${clientId}/cleaner_preferences`)
  },
  dislikeCleaner(clientId, cleanerId) {
    return axios.post(`/api/clients/${clientId}/cleaner_preferences`, { cleaner_id: cleanerId, like: false })
  },
  likeCleaner(clientId, cleanerId) {
    return axios.post(`/api/clients/${clientId}/cleaner_preferences`, { cleaner_id: cleanerId, like: true })
  },
  removeCleaner(clientId, cleanerId) {
    return axios.post(`/api/clients/${clientId}/cleaner_preferences/remove`, { cleaner_id: cleanerId })
  },
  setPreferredCleanersOnly(clientId) {
    return axios.put(`/api/clients/${clientId}/cleaner_preferences/only`)
  },
  clearPreferredCleanersOnly(clientId) {
    return axios.put(`/api/clients/${clientId}/cleaner_preferences/any`)
  },
  getDaySheets({ date, service_area_ids }) {
    const area = service_area_ids.length > 0 ? '&service_area_ids=' + service_area_ids : ''
    return axios.get(`/api/day_sheets?date=${date}` + area)
  },
  getCleanerInvoice(invoiceId) {
    return axios.get(`/api/cleaner_invoices/${invoiceId}`)
  },
  getClientInvoice(invoiceId) {
    return axios.get(`/api/client_invoices/${invoiceId}`)
  },
  addAdjustmentToClientInvoice(invoiceId, params) {
    return axios.post(`/api/client_invoices/${invoiceId}/adjustments`, params)
  },
  updateClientInvoice(invoiceId, params) {
    return axios.put(`/api/client_invoices/${invoiceId}`, { client_invoice: params })
  },
  updateCleanerInvoice(invoiceId, params) {
    return axios.put(`/api/cleaner_invoices/${invoiceId}`, { cleaner_invoice: params })
  },
  applyPendingClientInvoiceChanges(invoiceId) {
    return axios.put(`/api/client_invoices/${invoiceId}/apply`)
  },
  applyPendingCleanerInvoiceChanges(invoiceId) {
    return axios.put(`/api/cleaner_invoices/${invoiceId}/apply`)
  },
  markInvoicePaid(invoiceId, { payment_type, description }) {
    return axios.put(`/api/client_invoices/${invoiceId}/mark_as_paid`, { payment_type, description })
  },
  refundInvoice(invoiceId, amount_cents, reason, reason_text, applies_for_cleaners, method, confirm) {
    return axios.post(`/api/client_invoices/${invoiceId}/refund`, { amount_cents: amount_cents, reason: reason, reason_text: reason_text, applies_for_cleaners: applies_for_cleaners, refund_method: method, confirm: confirm })
  },
  removeInvoicePayment(invoiceId) {
    return axios.put(`/api/client_invoices/${invoiceId}/unpay`)
  },
  sendPaymentReminder(invoiceId) {
    return axios.put(`/api/client_invoices/${invoiceId}/send_payment_remind`)
  },
  voidInvoice(invoiceId) {
    return axios.delete(`/api/client_invoices/${invoiceId}`)
  },
  deleteEvent(eventId) {
    return axios.delete(`/api/events/${eventId}`)
  },
  removePendingChange(eventId) {
    return axios.delete(`/api/events/${eventId}/pending_change`)
  },
  updateEvent(eventId, params) {
    return axios.put(`/api/events/${eventId}`, params)
  },
  getCleanerNotifications(cleanerId) {
    return axios.get(`/api/cleaners/${cleanerId}/notifications`)
  },
  getDisputes(params) {
    const queryString = new URLSearchParams(params).toString()
    return axios.get(`/api/disputes?${queryString}`)
  },
  getAccessibleAreas() {
    return axios.get('/api/service_areas')
  },
  getPickableAreas() {
    return axios.get('/api/service_areas?pickable=1')
  },
  getAllAreas() {
    return axios.get('/api/service_areas?all=1')
  },
  getAreasSettings() {
    return axios.get('/api/profile/areas')
  },
  updateAreasSettings(ids) {
    return axios.patch('/api/profile/areas', { ids: ids })
  },
  disableUser(userId, reason) {
    return axios.put(`/api/users/${userId}/disable`, { reason: reason })
  },
  disableClaimUser(userId) {
    return axios.put(`/api/users/${userId}/disable_claim`)
  },
  enableClaimUser(userId) {
    return axios.put(`/api/users/${userId}/enable_claim`)
  },
  enableUser(userId) {
    return axios.put(`/api/users/${userId}/enable`)
  },
  reinstateCleaner(cleanerId) {
    return axios.put(`/api/users/${cleanerId}/reinstate`)
  },
  getHweServices() {
    return axios.get('/api/hwe_services')
  },
  getHweService(serviceId) {
    return axios.get(`/api/hwe_services/${serviceId}`)
  },
  updateHweService(serviceId, args) {
    return axios.put(`/api/hwe_services/${serviceId}`, { hwe_service: args })
  },
  updateLocale(locale) {
    return axios.patch('/api/profile/locale', locale)
  },
  updateProfileLocale(profileId, locale) {
    return axios.patch(`/api/profiles/${profileId}/locale`, locale)
  },
  updateProfileOption(params) {
    return axios.post('/api/profile', params)
  },
  sendStartDisputeLink(jobId) {
    return axios.put(`/api/cleaning_jobs/${jobId}/send_start_dispute_link`)
  },
  startCheckr(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/start_checkr`)
  },
  startVouched(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/start_vouched`)
  },
  getPaymentCenterCleaners(params) {
    return axios.post('/api/payment_center', params)
  },
  getPaymentCenterCleanersCalculate(params) {
    return axios.post('/api/payment_center/calculate', params)
  },
  exportPaymentCenterUnpaidTotals(params) {
    return axios.post('/api/payment_center/export/', params)
  },
  mergeCleanerInvoices(params) {
    return axios.post('/api/payment_center/finalize', params)
  },
  getEmailTemplate(templateKey) {
    return axios.get(`/api/email_templates/${templateKey}`)
  },
  getMergedCleanerInvoices(invoiceIds) {
    return axios.post('/api/payment_center/invoices', { ids: invoiceIds })
  },
  paymentCenterPayCleanerInvoices(invoiceIds) {
    return axios.post('/api/payment_center/pay', { ids: invoiceIds })
  },
  payCleanerInvoiceOnStripe(cleanerInvoiceId) {
    return axios.put(`/api/cleaner_invoices/${cleanerInvoiceId}/pay_on_stripe`)
  },
  payCleanerInvoicesOnStripe(mergedCleanerInvoiceId) {
    return axios.put('/api/cleaner_payouts/pay_on_stripe', { merged_cleaner_invoice_id: mergedCleanerInvoiceId })
  },
  unpayCleanerInvoices(mergedCleanerInvoiceId) {
    return axios.put('/api/cleaner_payouts/unpay', { merged_cleaner_invoice_id: mergedCleanerInvoiceId })
  },
  unmergeCleanerInvoices(mergedCleanerInvoiceId) {
    return axios.delete(`/api/cleaner_payouts/${mergedCleanerInvoiceId}/unmerge`)
  },
  markCleanerPaymentAsPaid(mergedCleanerInvoiceId, method) {
    return axios.put('/api/cleaner_payouts/mark_as_paid', { merged_cleaner_invoice_id: mergedCleanerInvoiceId, payment_method: method })
  },
  unlockCleanerInvoiceForStripe(cleanerInvoiceId) {
    return axios.put(`/api/cleaner_invoices/${cleanerInvoiceId}/unlock_for_stripe`)
  },
  addPaymentCenterAdjustments(params) {
    return axios.post('/api/payment_center/adjustments', ...params)
  },
  getCleanerTipInvoice(invoiceId) {
    return axios.get(`/api/cleaner_tip_invoices/${invoiceId}`)
  },
  unassignAllJob(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/unassign_jobs`)
  },
  unassignJob(jobId, args) {
    return axios.delete(`/api/cleaning_jobs/${jobId}`, { params: args })
  },
  updateProperty(propertyId, params) {
    return axios.patch(`/api/properties/${propertyId}`, params)
  },
  getJobMeetupDetails(jobId) {
    return axios.get(`/api/cleaning_jobs/${jobId}/meetup`)
  },
  getCleanerPositions(params) {
    return axios.get(`/api/cleaner_positions?${params}`)
  },
  getCleanerPayouts(cleanerId) {
    return axios.get(`/api/cleaner_payouts?cleaner_id=${cleanerId}`)
  },
  getJobPositions(jobId) {
    return axios.get(`/api/cleaner_positions?job_id=${jobId}`)
  },
  getVisitReimbursement(reservationId) {
    return axios.get(`/api/reimbursements?reservation_id=${reservationId}`)
  },
  updateVisitReimbursement(reservationId, params) {
    return axios.post('/api/reimbursements', { reservation_id: reservationId, reimbursement: params })
  },
  removeVisitReimbursement(reservationId) {
    return axios.delete(`/api/reimbursements/${reservationId}`)
  },
  getVisitBonus(reservationId) {
    return axios.get(`/api/bonuses?reservation_id=${reservationId}`)
  },
  updateVisitBonus(reservationId, params) {
    return axios.post('/api/bonuses', { reservation_id: reservationId, bonus: params })
  },
  removeVisitBonus(reservationId) {
    return axios.delete(`/api/bonuses/${reservationId}`)
  },
  getProblems(params) {
    return axios.get(`/api/problems`, { params: params })
  },
  notifyCleaners(visitIds, date) {
    return axios.post(`/api/problems/notify`, { visit_ids: visitIds, date: date })
  },
  captureAmount(paymentIntentId) {
    return axios.put(`/api/stripe/payment_intents/${paymentIntentId}/capture`)
  },
  getReports(params) {
    return axios.post('/api/reports', params)
  },
  exportReport(params) {
    return axios.post('/api/reports/export', params)
  },
  requirePhoneConfirmation(phoneId) {
    return axios.put(`/api/phones/${phoneId}/require_confirmation`)
  },
  quickCreateClient(params) {
    return axios.post('/api/quick_clients', { client: params })
  },
  getReconciliationData(params) {
    return axios.post('/api/reconciliation', params)
  },
  updateCleanerAvailability(cleanerId, availabilities) {
    return axios.post(`/api/cleaners/${cleanerId}/availabilities`, availabilities)
  },
  getCleanerAvailability(cleanerId) {
    return axios.get(`/api/cleaners/${cleanerId}/availabilities`)
  },
  deleteStripeAccount(areaKey, accountId) {
    return axios.delete('/api/stripe/accounts', { params: { area_key: areaKey, account_id: accountId } })
  },
  sendPaymentInfoRequired(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/request_payment_info`)
  },
  sendStripeOnboarding(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/send_stripe_onboarding`)
  },
  toggleUseStripeConnect(cleanerId, newStatus) {
    if (newStatus) {
      return axios.put(`/api/cleaners/${cleanerId}/set_use_stripe_connect`)
    }
    return axios.put(`/api/cleaners/${cleanerId}/reset_use_stripe_connect`)
  },
  checkStripeOnboarding(cleanerId) {
    return axios.put(`/api/cleaners/${cleanerId}/check_onboarding`)
  },
  updateStripeConnectAccount(cleanerId, params) {
    return axios.post(`/api/cleaners/${cleanerId}/connect`, params)
  },
  getInspectionReportForVisit(visitId) {
    return axios.get(`/api/reservations/${visitId}/inspection`)
  },
  createCouponCode(params) {
    return axios.post('/api/coupon_codes', { coupon_code: params })
  },
  createDiscountBundle(params) {
    return axios.post('/api/discount_bundles', { discount_bundle: params })
  },
  getDiscountBundle(bundleId) {
    return axios.get(`/api/discount_bundles/${bundleId}`)
  }
}
